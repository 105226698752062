@font-face {
  font-family: 'myFont';
  src: url('./asset/fonts/Papyrus.ttf');
}
.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  background: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* position: fixed;
  top: 0;
  z-index: 999; */
  background-color: black;
  /* height: 100vh; */
  min-height: 10vmin;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: calc(10px + 1.2vmin);
  font-weight: 800;
  font-family: 'myFont';
  color: white;
  box-shadow: 0 0 7px rgba(84, 27, 218, 0.1)!important;
  -webkit-box-shadow: 0 0 7px rgba(84, 27, 218, 0.1)!important;
}
.App-header-item {
  margin: 0 6vmin;
  cursor: pointer;
}

.active {
  color: #1296db;
}

.App-header-item:hover {
  color: lightblue;
}

.App-header-down {
  height: 3vmin;
  position: absolute;
  top: 3vmin;
  padding-left: 0.4vmin;
}

.App-menu-modal {
  position: absolute;
  top: 10vmin;
  z-index: 9999;
  width: 100%;
  border-radius: 0.2vmin;
}

.App-menu-modal-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.App-menu-modal-item {
  padding: 2vmin;
  background: rgb(2, 2, 12);
  font-family: 'myFont';
  font-size: calc(10px + 1.2vmin);
  color: white;
  max-width: 100vw;
  cursor: pointer;
}

.App-menu-modal-item:hover {
  color: #1296db;
  font-weight: 600;
}

.App-menu-modal-item:hover .App-menu-modal-item-img {
  /* height: 130px;
  width: 85px; */
  height: 210px;
  width: 138px;
}


.App-menu-modal-item-img {
  height: 105px;
  width: 69px;
  object-fit: cover;
}

.App-menu-modal-item-name {
  padding-top: 4vmin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 960px) {
  .App-header {
    font-size: 12px;
  }
  .App-header-item {
    margin: 0 1.2vmin;
  }
  .App-header-down {
    display: none;
  }
}