* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// html,
// body {
//     height: 100%;
// }

ul {
  padding-inline-start: 0px;
  li {
    list-style-type: none;
  }
}


.pageBox {
  overflow: auto;
  height: 100vh;
}

.et_pb_row {
  width: 80%;
  max-width: 1080px;
  margin: auto;
}

// 提交、保存、返回(自带左间距为5px)
.btn_main {
  display: block;
  width: 120px;
  height: 38px;
  line-height: 38px;
  border-radius: 4px;
  background: #1296db;
  border: 1px solid #1296db;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;

  &:hover {
    border: 1px solid #418ffa;
    background: #418ffa;
    color: #ffffff;
  }

  &:active {
    border: 1px solid #2784ee;
    background: #2784ee;
  }

  &.disabled {
    border: 1px solid #e6e6e6;
    color: #e6e6e6;
    cursor: not-allowed;
  }
}

.btn_cancel {
  display: block;
  width: 120px;
  height: 38px;
  line-height: 38px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  font-family: MicrosoftYaHei;
  color: #5b5b5b;
  font-size: 14px;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: 1px solid #4c4c4c;
  }

  &:active {
    border: 1px solid #070e31;
    color: #070e31;
  }

  &.disabled {
    border: 1px solid #d8d8d8;
    color: #a2a3a9;
    cursor: not-allowed;
  }
}

.ant-input {
  // -webkit-appearance: none;
  padding: 10px 4% !important;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  width: 100%;
  font-weight: 400;
  border-width: 0;
  border-radius: 0.4vmin;
  // border: 1px solid rgb(224, 222, 222);
}
.ant-input:focus,
.ant-input-focused {
  border-color: #1296db;
}

@media only screen and (min-width: 960) {
  .et_pb_row {
    padding: 27px 0;
    width: 100%;
  }
}